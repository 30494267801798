


























import { Component, Prop, Vue, Mixins } from 'vue-property-decorator';
import { activateAccount, accountRegisterResend } from '@/apis/account.api';
import FormMixin from '@/mixins/form';
import {
  successPopupHandler,
  popupLoadings,
  errorPopupHandler,
  loadings,
} from '@/utils/decorators/VueTimmer';

@Component
export default class VerifyEmailRegister extends Mixins(FormMixin) {
  errorText = '';
  code = '';
  loadings = {
    register: false,
  };

  get email(): string {
    return this.$route.query.email as string;
  }

  @loadings('loadings.register')
  activate() {
    return activateAccount(this.email, this.code)
      .then((data) => {
        if (data.success === false) {
          return Promise.reject(new Error('Invalid code'));
        }
        return this.$store.dispatch('account/tryGetProfile');
      })
      .then(() => {
        this.$router.push({ name: 'account_detail' });
      })
      .catch(() => {
        this.errorText = 'Your activation code is invalid';
      });
  }

  @errorPopupHandler('Fail to send email verification')
  @successPopupHandler('Send email verification successfully')
  @popupLoadings('Sending email verification')
  resend() {
    return accountRegisterResend({
      email: this.email,
    });
  }
}
